// packages block
import { FC } from 'react';
// component block
import AddAgreementComponent from '../../../../components/main/agreements/addAgreement';


const AddAgreement: FC = () => {
  return (
    <AddAgreementComponent />
  )
};

export default AddAgreement;
